<template>
<header>
  <div class="container">
    <div class="header-wrapper">
      <div class="logo">
        <img src="@/assets/images/logo.png" alt="">
      </div>
      <div class="address">
        <button class="btn" @click="modalForm= !modalForm">Записаться на обследование</button>

      </div>

      <div class="phone">
        <a class="wt" href="https://wa.me/79372900303" target="_blank"><img src="@/assets/images/whatsapp.png"></a>
       <!-- <a class="wt"><img src="@/assets/images/tg.png"></a>-->
        <a href="tel:+79274830303">+7 (927) 483-03-03</a>
      </div>
    </div>
  </div>

  <div class="modal" v-if="modalForm" id="myModal" >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-center">Записаться на прием</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="modalForm= !modalForm"></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Ваше имя</label>
              <input type="text" class="form-control" v-model="name" placeholder="Ваше имя">
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Номер телефона</label>
              <input type="text" class="form-control" placeholder="+7 999 999 9999" v-maska="'+7 ### ### ####'" v-model="phone" >
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Ваш населенный пункт</label>
              <input type="text" class="form-control" v-model="location" placeholder="Ваш населенный пункт">
            </div>
          </form>
          <div class="invalid-feedback" v-if="popUpFormEmpty" style="display: block">
            Заполните все поля!
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="sendPopUp">Отправить</button>
          <p>Нажимая на кнопку Отправить, я соглашаюсь с условиями обработки моих данных</p>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" v-if="formSubmitSuccess">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Записаться на прием</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="formSubmitSuccess= !formSubmitSuccess"></button>
        </div>
        <div class="modal-body">
          <p>Ваша заявка отправлена!</p>
        </div>
      </div>
    </div>
  </div>


</header>
</template>

<script>
import { maska } from 'maska'
export default {
  name: "Page-Header",
  directives: { maska },
  data(){
    return{
      modalForm:false,
      name:"",
      phone:"",
      location:"",
      formSubmitSuccess:false,
      popUpFormEmpty:false
    }
  },
  methods: {
    sendPopUp() {
      if (!this.name || !this.phone || !this.location) {
        this.popUpFormEmpty = true
        return;
      } else {
        this.popUpFormEmpty = false
      }

      var params = new FormData();
      params.set('name', this.name);
      params.set('phone', this.phone);
      params.set('location', this.location);

      fetch(
          '/send/',
          {
            method: 'POST',
            body: params
          }
      )
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error('Something went wrong');
          })
          .then(() => {
            this.formSubmitSuccess = true;
            this.name = ""
            this.phone = ""
            this.location = ""
            this.modalForm = false
            this.sendSEOTarget()
          })
          .catch(() => {
            this.formSubmitSuccess = false;
          });
    },
    /**
     * Отправка цели Yandex.Metriaka
     * Для проверки работы цели добавить в УРЛ параметр ?_ym_debug=1
     * @param {Object} args
     * @param {String} args.reachGoal имя цели
     * @param {Number} args.counterID ID счетчика
     */
    sendSEOTarget(
        args = {
          counterID: 91530464,
          reachGoal: 'formReception'
        }
    ) {
      try {
        window[`yaCounter${args.counterID}`].reachGoal(args.reachGoal);
      } catch (error) {
        console.error(error);
      }
    }
  }
}
</script>

<style scoped>
header{
  background: #fff;
  padding: 11px 0;
}

.header-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.address{
  color: #09A3F2;
}
address{
  margin: 0;
}
.phone{
  display: flex;
  align-items: center;
}
.wt{
  margin-right: 15px;
}
.phone a{
  text-decoration: none;
  color: #09A3F2;
  font-size: 18px;
  font-weight: bold;
}
.address button{
  border: solid 1px #09b5f3;
  color: #09b5f3;
}
.modal{
  display: block;
}
.modal-footer{
  justify-content: center;
  text-align: center;
}
.modal-footer p{
font-size: 12px;
}
@media screen and (max-width: 768px) {
  .header-wrapper{
    flex-direction: column;
    align-items: center;
  }
  .address{
    margin-bottom: 20px;
    margin-top: 20px;
  }

}
</style>
